<template>
  <div class="justify-center">

      <Matcher :i18n_instructionMsg_key="'matchNumbersInstruction'" :matchList="match"/>

  </div>
</template>

<script>
import Matcher from './Matcher'

export default {
  components: {
    Matcher
  },

  methods: {

  },
  data: () => ({

    match: [{ eng: '0', guj: '૦', hindi: '०' }, { eng: '1', guj: '૧', hindi: '१' }, { eng: '2', guj: '૨', hindi: '२' }, { eng: '3', guj: '૩', hindi: '३' },
      { eng: '4', guj: '૪', hindi: '४' }, { eng: '5', guj: '૫', hindi: '५' }, { eng: '6', guj: '૬', hindi: '६' },
      { eng: '7', guj: '૭', hindi: '७' }, { eng: '8', guj: '૮', hindi: '८' }, { eng: '9', guj: '૯', hindi: '९' }]

  })
}
</script>
<style>

</style>
